export let MetricFormat;
(function (MetricFormat) {
    MetricFormat["CURRENCY"] = "currency";
    MetricFormat["NUMBER"] = "number";
    MetricFormat["PERCENTAGE"] = "percentage";
})(MetricFormat || (MetricFormat = {}));
export let MetricTrend;
(function (MetricTrend) {
    MetricTrend["POSITIVE"] = "positive";
    MetricTrend["NEGATIVE"] = "negative";
})(MetricTrend || (MetricTrend = {}));
